const root = {
  fontFamily: "body",
  lineHeight: "body",
  fontWeight: "body",
  fontSize: "16px",
  h1: {
    color: "textHeadings",
    fontFamily: "heading",
    lineHeight: "heading",
    fontWeight: "heading",
    mt: 3,
    mb: 1,
  },
  h2: {
    color: "textHeadings",
    fontFamily: "heading",
    lineHeight: "heading",
    fontWeight: "heading",
    mt: 3,
    mb: 1,
  },
  h3: {
    color: "textHeadings",
    fontFamily: "heading",
    lineHeight: "heading",
    fontWeight: "heading",
    mt: 3,
    mb: 1,
  },
  h4: {
    color: "textHeadings",
    fontFamily: "heading",
    lineHeight: "heading",
    fontWeight: "heading",
    mt: 4,
    mb: 2,
  },
  h5: {
    color: "textHeadings",
    fontFamily: "heading",
    lineHeight: "heading",
    fontWeight: "heading",
    mt: 4,
    mb: 1,
  },
  p: {
    mt: 0,
    mb: 3,
    color: "inherit",
    fontFamily: "body",
    fontWeight: "body",
    lineHeight: "body",
  },
  small: {
    fontSize: 12,
  },
  a: {
    color: "primary",
    textDecoration: "none",
  },
  blockquote: {
    borderLeftWidth: 3,
    borderLeftStyle: "solid",
    borderLeftColor: "primary",
    color: "charCoal",
    my: 4,
    mx: 0,
    px: 3,
    fontSize: "1.1em",
  },
  ol: {
    margin: "0 0 1.5em",
    minWidth: "100%",
    maxWidth: "100%",
    paddingLeft: "1.3em",
    paddingRight: "1.5em",
    listStyle: "decimal",
  },
  ul: {
    margin: "0 0 1.5em",
    minWidth: "100%",
    maxWidth: "100%",
    paddingLeft: "1.3em",
    paddingRight: "1.5em",
    listStyle: "disc",
  },
  li: {
    paddingLeft: "0.3em",
    whiteSpace: "pre-wrap",
    mb: 3,
  },
}

export default root
