import styles from "./styles"
import buttons from "./buttons"
import colors from "./colors"
import badges from "./badges"

// https://theme-ui.com/sx-prop/#theme-aware-properties

const theme = {
  breakpoints: ["576px", "768px", "992px", "1200px"],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    body: "Open Sans, sans-serif",
    heading: "Nunito, sans-serif",
    monospace: "Inconsolata, monospace",
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96, 108],
  fontWeights: {
    body: 400,
    heading: 900,
    bold: 700,
  },
  lineHeights: {
    body: 1.625,
    heading: 1.25,
  },
  colors,
  buttons,
  styles,
  badges,
}
export default theme
