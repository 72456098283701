const colors = {
  text: "#000",
  textHeadings: "#3c3b3b",
  placeholderText: "#646464",
  background: "#e8e6e6",
  secondaryBg: "#cccccc",
  primary: "#609",
  secondary: "#306",
  muted: "#f6f6f6",
  divider: "#d6d6d6",
  black: "#000",
  purple: "#609",
  charCoal: "#36454f",
  orange: "#ff7b00",
}

export default colors
