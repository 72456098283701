const buttons = {
  primary: {
    color: "background",
    bg: "primary",
    fontFamily: "heading",
    fontWeight: "bold",
    borderRadius: 8,
  },
  secondary: {
    color: "background",
    bg: "secondary",
    fontFamily: "heading",
    fontWeight: "bold",
  },
  text: {
    color: "text",
    bg: "transparent",
    opacity: 0.72,
  },
}

export default buttons
