module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/amooto-icon-rounded.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f158d057f6e9eee584d579b4948bd07e"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"amooto","accessToken":"MC5ZSno0QXhFQUFDRUFRZGFR.77-9BBXvv70j77-977-9Bu-_vRB077-9ew3vv73vv71s77-9ITgd77-9bO-_ve-_vTBOCO-_vR0LWw","schemas":{"frequently_asked_questions":{"Main":{"page_name":{"type":"StructuredText","config":{"single":"heading1","label":"Page Name","placeholder":"Enter page name"}},"hero_subtitle":{"type":"StructuredText","config":{"single":"paragraph","label":"Hero Subtitle","placeholder":"You have questions, we have answers"}},"uid":{"type":"UID","config":{"label":"Slug","placeholder":"Enter slug"}},"questions":{"type":"Group","config":{"fields":{"question":{"type":"StructuredText","config":{"single":"heading2","label":"Question","placeholder":"How safe is the journey?"}},"answer":{"type":"StructuredText","config":{"multi":"paragraph, heading3, heading4, heading5, heading6, strong, em, hyperlink, image, embed, list-item, o-list-item, o-list-item","allowTargetBlank":true,"label":"Answer","placeholder":"The trip to Mars cannot be called risk free. Like any venture..."}},"category":{"type":"Link","config":{"select":"document","customtypes":["question_category"],"label":"Category"}}},"label":"Questions"}}},"SEO":{"meta_title":{"type":"StructuredText","config":{"single":"heading1","label":"Meta Title","placeholder":"Enter meta title"}},"meta_description":{"type":"StructuredText","config":{"single":"paragraph","label":"Meta Description","placeholder":"Enter meta description"}},"open_graph_image":{"type":"Image","config":{"constraint":{"width":1200,"height":630},"thumbnails":[],"label":"Open Graph Image"}}}},"question_category":{"Main":{"category_name":{"type":"StructuredText","config":{"single":"heading1","label":"Category Name","placeholder":"Category Name"}},"uid":{"type":"UID","config":{"label":"Category Slug","placeholder":"category-slug"}}}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-145451682-1","cookieName":"_ga","anonymize":false,"allowAdFeatures":false},"googleTagManager":{"trackingId":"GTM-KRDZ6VC","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"environments":["production","development"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
