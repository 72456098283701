const badges = {
  primary: {
    bg: "black",
    borderRadius: 5,
    px: 2,
    py: 1,
  },
}

export default badges
